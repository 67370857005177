import React from "react";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { Fieldset } from "primereact/fieldset";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer, toast } from "react-toastify";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

import "../src/styles/style.css";
import MiscellaneousServiceVoucher from "./MiscellaneousServiceVoucher";
class Miscellaneous extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      id: 0,
      miscellanea: [],
      jobCardStatusId:0,
      miscellaneous: null,
      misDate: null,
      paxName: "",
      passportNo: "",
      visaTypeId: 0,
      isVatIncludedCost: false,
      isVatIncludedSell: false,
      costBaseAmount: "",
      costTaxAmount: "",
      costTotalAmount: "",
      sellBaseAmount: "",
      sellTaxAmount: "",
      sellTotalAmount: "",
      remarks: "",
      isError: false,
      errorMessage: "",
      paxNumber: "",
      isSaveProcessing: false,
      isRemoveProcessing: false,
      isDuplicateProcessing: false,
      vendors: [],
      vendorId: "",
      vendorName: "",
      isFinance: false,
      roleId: 0,
      selectedItem: null,
      isModalOpenCopy: false,
    };
  }
  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    const roleId = localStorage.getItem("roleId");
    this.setState({
      roleId: roleId,
    });
    await this.getReferenceData();
    await this.getJobMiscellanea();
  };

  getReferenceData = async () => {
    this.props.setLoader(true);
    var vendorList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Master/GetReferanceData`,
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        debugger;
        response.vendors.map((o) => {
          if (o.vendorType.includes(4)) {
            vendorList.push(o);
          }
        });
        this.props.setLoader(false);
        this.setState({
          vendors: vendorList,
        });
      });
  };

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }

  getJobMiscellanea = async () => {
    this.props.setLoader(true);
    const miscellaneousList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobMiscellaneaByJobCardId?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardMiscellaneous.map((i) =>
          miscellaneousList.push({
            id: i.id,
            miscellaneousCode: i.miscellaneousCode,
            venderName: i.vendorName,
            jobCardNo: i.jobCardNo,
            venderAddress1: i.venderAddress1,
            venderAddress2: i.venderAddress2,
            vendorId: i.vendorId,
            paxName: i.paxName,
            paxNumber: i.paxNumber,
            description: i.description,
            misDate:
              i.misDate == null
                ? null
                : dayjs(i.misDate).format("DD/MM/YYYY HH:mm"),
                misDateVoucher:
                i.misDate == null
                  ? null
                  : dayjs(i.misDate).format("DD/MM/YYYY"),
            remarks: i.remarks,
            costTaxAmount:
              i.costTaxAmount == null ? null : i.costTaxAmount.toFixed(2),
            sellTaxAmount:
              i.sellTaxAmount == null ? null : i.sellTaxAmount.toFixed(2),
            totalCostPrice:
              i.totalCostPrice == null ? null : i.totalCostPrice.toFixed(2),
            totalSellPrice:
              i.totalSellPrice == null ? null : i.totalSellPrice.toFixed(2),
            isInvoiced: i.isInvoiced,
            isFinance: i.isFinance,
          })
        );
        this.props.setLoader(false);
        this.setState({
          miscellanea: miscellaneousList,
          jobCardStatusId:response.jobCardStatusId
        });
      });
  };

  handleCostTaxChange(costBase) {
    var costTax = 0;
    var costTotal = 0;
    if (this.state.isVatIncludedCost) {
      var tax = parseFloat(costBase) * 0.05;
      costTax = tax;
    }
    costTotal = parseFloat(costBase) + parseFloat(costTax);
    this.setState({
      costBaseAmount: costBase,
      costTaxAmount: costTax.toFixed(2),
      costTotalAmount: costTotal.toFixed(2),
    });
  }
  handleSellTaxChange(sellBase) {
    var sellTax = 0;
    var sellTotal = 0;
    if (this.state.isVatIncludedSell) {
      var tax = parseFloat(sellBase) * 0.05;
      sellTax = tax;
    }
    sellTotal = parseFloat(sellBase) + parseFloat(sellTax);
    this.setState({
      sellBaseAmount: sellBase,
      sellTaxAmount: sellTax.toFixed(2),
      sellTotalAmount: sellTotal.toFixed(2),
    });
  }

  handleCostTaxFlagChange(vat) {
    if (this.state.costBaseAmount && this.state.costBaseAmount > 0) {
      var costTax = 0;
      var costTotal = 0;
      var costBase = this.state.costBaseAmount;
      if (vat) {
        var tax = parseFloat(costBase) * 0.05;
        costTax = tax;
      }
      costTotal = parseFloat(costBase) + parseFloat(costTax);
      this.setState({
        costBaseAmount: costBase,
        costTaxAmount: costTax.toFixed(2),
        costTotalAmount: costTotal.toFixed(2),
      });
    }
    this.setState({
      isVatIncludedCost: vat,
    });
  }

  handleSellTaxFlagChange(vat) {
    if (this.state.sellBaseAmount && this.state.sellBaseAmount > 0) {
      var sellBase = this.state.sellBaseAmount;
      var sellTax = 0;
      var sellTotal = 0;
      if (vat) {
        var tax = parseFloat(sellBase) * 0.05;
        sellTax = tax;
      }
      sellTotal = parseFloat(sellBase) + parseFloat(sellTax);
      this.setState({
        sellBaseAmount: sellBase,
        sellTaxAmount: sellTax.toFixed(2),
        sellTotalAmount: sellTotal.toFixed(2),
      });
    }

    this.setState({
      isVatIncludedSell: vat,
    });
  }
  handleChange(changeObject) {
    this.setState(changeObject);
    this.setState({
      isSubmited: false,
    });
  }

  footerContent = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={(e) => {
            this.setState({ isModalOpen: false });
          }}
          severity="secondary"
        />
{(this.state.jobCardStatusId==0||this.state.jobCardStatusId==1) && (
        <>
        {this.state.id > 0 && (
          <>
            {!this.state.isInvoiced && (
              <Button
                label="Delele"
                icon="pi pi-times"
                severity="danger"
                loading={this.state.isRemoveProcessing}
                onClick={(e) => {
                  this.removeJobMiscellaneousHandler(e);
                }}
              />
            )}
            <Button
              label="Duplicate"
              icon="pi pi-copy"
              loading={this.state.isDuplicateProcessing}
              onClick={(e) => {
                this.setState({ isModalOpen: false });
                this.confirm();
              }}
            />
          </>
        )}
        {!this.state.isInvoiced && (
          <Button
            label="Save"
            icon="pi pi-check"
            loading={this.state.isSaveProcessing}
            onClick={(e) => {
              this.saveMiscellaneousHandler(e);
            }}
          />
        )}
         </>
      )}
        <ConfirmDialog />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  };

  setErrorMessage = (message, type) => {
    if (type == 1) {
      toast.success(message);
    }

    if (type == 2) {
      toast.warn(message);
    }

    if (type == 3) {
      toast.error(message);
    }
  };

  reject = () => {
    this.setErrorMessage("Process Cancel", 2);
  };

  accept = () => {
    this.duplicateMiscellaneousHandler();
  };

  confirm = () => {
    debugger;
    var accept = this.accept;
    var reject = this.reject;
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  duplicateMiscellaneousHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isDuplicateProcessing: true,
    });
    var payload = {
      JobCardId: this.props.id,
      VendorId: this.state.vendorId,
      PaxName: this.state.paxName,
      PaxNumber: this.state.paxNumber,
      Description: this.state.description,
      Remarks: this.state.remarks,
      MisDate:
        this.state.misDate == null ? null : this.state.misDate.toLocaleString(),
      IsFinance: this.state.isFinance ? this.state.isFinance : false,
      IsVatIncludedCost: this.state.isVatIncludedCost
        ? this.state.isVatIncludedCost
        : false,
      IsVatIncludedSell: this.state.isVatIncludedSell
        ? this.state.isVatIncludedSell
        : false,
      CostBaseAmount:
        this.state.costBaseAmount == "" ? 0 : this.state.costBaseAmount,
      CostTaxAmount:
        this.state.costTaxAmount == "" ? 0 : this.state.costTaxAmount,
      SellBaseAmount:
        this.state.sellBaseAmount == "" ? 0 : this.state.sellBaseAmount,
      SellTaxAmount:
        this.state.sellTaxAmount == "" ? 0 : this.state.sellTaxAmount,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Job/SaveMiscellaneous`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isDuplicateProcessing: false,
            errorMessage: response.data.successMessage,
            isModalOpen: false,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isDuplicateProcessing: false,
        });
        this.resetFields();
        this.getJobMiscellanea();
      });
  };

  openTableModal = async (e) => {
    this.resetFields();
    await this.getJobMiscellaneous(e.value.id);
  };

  getJobMiscellaneous = async (id) => {
    this.props.setLoader(true);
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobMiscellaneousById?` +
        new URLSearchParams({
          id: id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        this.props.setLoader(false);
        this.setState({
          id: response.jobCardMiscellaneous.id,
          vendorId: response.jobCardMiscellaneous.vendorId,
          paxName: response.jobCardMiscellaneous.paxName,
          paxNumber: response.jobCardMiscellaneous.paxNumber,
          description: response.jobCardMiscellaneous.description,
          remarks: response.jobCardMiscellaneous.remarks,
          misDate:
            response.jobCardMiscellaneous.misDate == null
              ? null
              : response.jobCardMiscellaneous.misDate,
          misDateVoucher:
            response.jobCardMiscellaneous.misDate == null
              ? null
              : dayjs(response.jobCardMiscellaneous.misDate).format(
                  "DD/MM/YYYY"
                ),
          isVatIncludedCost: response.jobCardMiscellaneous.isVatIncludedCost,
          isVatIncludedSell: response.jobCardMiscellaneous.isVatIncludedSell,
          costBaseAmount: response.jobCardMiscellaneous.costBaseAmount,
          costTaxAmount:
            response.jobCardMiscellaneous.costTaxAmount == null
              ? null
              : response.jobCardMiscellaneous.costTaxAmount.toFixed(2),
          costTotalAmount:
            response.jobCardMiscellaneous.totalCostPrice == null
              ? null
              : response.jobCardMiscellaneous.totalCostPrice.toFixed(2),
          sellBaseAmount: response.jobCardMiscellaneous.sellBaseAmount,
          sellTaxAmount:
            response.jobCardMiscellaneous.sellTaxAmount == null
              ? null
              : response.jobCardMiscellaneous.sellTaxAmount.toFixed(2),
          sellTotalAmount:
            response.jobCardMiscellaneous.totalSellPrice == null
              ? null
              : response.jobCardMiscellaneous.totalSellPrice.toFixed(2),
          isFinance: response.jobCardMiscellaneous.isFinance,
          isModalOpen: true,
        });
      });
  };

  removeJobMiscellaneousHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isRemoveProcessing: true,
    });
    var payload = {
      Id: this.state.id,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Job/RemoveMiscellaneous`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isRemoveProcessing: false,
            errorMessage: response.data.successMessage,
            isModalOpen: false,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isRemoveProcessing: false,
        });
        this.resetFields();
        this.setState({ isModalOpen: false });
        this.getJobMiscellanea();
      });
  };

  validation = () => {
    if (this.state.paxName == "") {
      this.setErrorMessage("Please enter passenger name.", 3);
      return false;
    }

    if (this.state.paxNumber == "") {
      this.setErrorMessage("Please enter passenger number.", 3);
      return false;
    }

    if (this.state.description == "") {
      this.setErrorMessage("Please enter description.", 3);
      return false;
    }

    if (this.state.misDate == null) {
      this.setErrorMessage("Please enter miscellaneous date.", 3);
      return false;
    }

    if (this.state.vendorId == "") {
      this.setErrorMessage("Please select vendor.", 3);
      return false;
    }

    return true;
  };

  saveMiscellaneousHandler = async (e) => {
    this.props.setLoader(true);
    this.setState({
      isSaveProcessing: true,
    });
debugger
    if (!this.validation()) {
      this.props.setLoader(false);
      this.setState({
        isSaveProcessing: false,
      });
      return;
    }

    var payload = {
      Id: this.state.id,
      JobCardId: this.props.id,
      VendorId: this.state.vendorId,
      PaxName: this.state.paxName,
      PaxNumber: this.state.paxNumber,
      Description: this.state.description,
      Remarks: this.state.remarks,
      MisDate:
        this.state.misDate == null ? null : this.state.misDate.toLocaleString(),
      IsVatIncludedCost: this.state.isVatIncludedCost
        ? this.state.isVatIncludedCost
        : false,
      IsVatIncludedSell: this.state.isVatIncludedSell
        ? this.state.isVatIncludedSell
        : false,
      CostBaseAmount:
        this.state.costBaseAmount == "" ? 0 : this.state.costBaseAmount,
      CostTaxAmount:
        this.state.costTaxAmount == "" ? 0 : this.state.costTaxAmount,
      SellBaseAmount:
        this.state.sellBaseAmount == "" ? 0 : this.state.sellBaseAmount,
      SellTaxAmount:
        this.state.sellTaxAmount == "" ? 0 : this.state.sellTaxAmount,
      IsFinance: this.state.isFinance ? this.state.isFinance : false,
    };
    const currentUser = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Job/SaveMiscellaneous`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${currentUser}`,
          },
        }
      )
      .then((response) => {
        if (!response.data.created) {
          this.props.setLoader(false);
          this.setState({
            isError: true,
            isSaveProcessing: false,
            errorMessage: response.data.successMessage,
            isModalOpen: false,
          });
        }
        this.props.setLoader(false);
        this.setState({
          isSaveProcessing: false,
          isModalOpen: false,
        });
        this.resetFields();
        this.getJobMiscellanea();
      });
  };

  resetFields = () => {
    this.setState({
      id: 0,
      paxName: "",
      paxNumber: "",
      passportNo: "",
      visaTypeId: 0,
      isVatIncludedCost: false,
      isVatIncludedSell: false,
      costBaseAmount: "",
      costTaxAmount: "",
      costTotalAmount: "",
      sellBaseAmount: "",
      sellTaxAmount: "",
      sellTotalAmount: "",
      description: "",
      misDate: null,
      isInvoiced: false,
      isFinance: false,
      remarks: "",
      vendorId: "",
      isModalVOpen: false,
    });
  };
  isInvoiced = (option) => {
    return (
      <>
        {option.isInvoiced ? (
          <img src="../vendors/images/checked.png" width="24" alt="" />
        ) : (
          <img src="../vendors/images/unchecked.png" width="24" alt="" />
        )}
      </>
    );
  };

  openVoucher = (option) => {
    return (
      <>
        <Button
          style={{ width: "2rem", height: "2rem" }}
          className="m-1"
          onClick={(e) =>
            this.setState({
              isModalOpenCopy: true,
              selectedItem: option,
            })
          }
          icon="pi pi-copy"
          severity="secondary"
          tooltip="Open Vouchers"
        />
      </>
    );
  };
  render() {
    return (
      <div>
        {(this.state.jobCardStatusId==0||this.state.jobCardStatusId==1) && (
        <>
        <Button
          icon="pi pi-plus"
          style={{ margin: "5px" }}
          severity="info"
          tooltip="Add New"
          onClick={(e) => {
            this.resetFields();
            this.setState({
              isModalOpen: true,
              miscellaneous: null,
            });
          }}
          tooltipOptions={{ position: "top" }}
        />
        </>
      )}
        <DataTable
          stripedRows
          showGridlines
          value={this.state.miscellanea}
          selectionMode="single"
          onSelectionChange={(e) => {
            this.openTableModal(e);
          }}
          dataKey="id"
          metaKeySelection={false}
          sortMode="multiple"
          paginator
          rows={20}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="miscellaneousCode"
            sortable
            header="Booking Ref"
          ></Column>
          <Column field="venderName" sortable header="Vendor Name"></Column>
          <Column field="paxName" sortable header="Pax Name"></Column>
          <Column field="description" sortable header="Description"></Column>
          <Column
            field="misDate"
            sortable
            header="Date & Time"
            style={{ fontWeight: "bold" }}
          ></Column>
          <Column field="remarks" sortable header="Remarks"></Column>
          <Column field="totalCostPrice" sortable header="Buy"></Column>
          <Column field="totalSellPrice" sortable header="Sell"></Column>
          <Column body={this.isInvoiced} sortable header="Is Invoiced"></Column>
          <Column body={this.openVoucher} sortable header="Actions"></Column>
        </DataTable>
        <Dialog
          maximizable
          headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
          contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
          style={{ width: "50vw" }}
          header="Add Miscellaneous"
          visible={this.state.isModalOpen}
          onHide={(e) => {
            this.setState({ isModalOpen: false });
          }}
          footer={this.footerContent()}
        >
          <form>
            <Fieldset legend="Main Details">
              <div className="form-group row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">
                      Pax Name *
                    </label>
                    <div className="col-sm-12 col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.paxName}
                        onChange={(e) =>
                          this.handleChange({
                            paxName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">
                      Vendor *
                    </label>
                    <div className="col-sm-12 col-md-10">
                      <Dropdown
                        value={this.state.vendorId}
                        onChange={(e) => {
                          this.handleChange({
                            vendorId: e.value,
                          });
                        }}
                        options={this.state.vendors}
                        placeholder="Select a Vendor"
                        optionLabel="code"
                        className="col-12"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">
                      Pax Number *
                    </label>
                    <div className="col-sm-12 col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        value={this.state.paxNumber}
                        onChange={(e) =>
                          this.handleChange({
                            paxNumber: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Description *
                </label>
                <div className="col-sm-12 col-md-10">
                  <textarea
                    className="form-control"
                    value={this.state.description}
                    onChange={(e) =>
                      this.handleChange({ description: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">
                      Miscellaneous Date *
                    </label>
                    <div className="col-sm-12 col-md-10">
                      <input
                        className="form-control"
                        value={this.state.misDate}
                        onChange={(e) =>
                          this.handleChange({
                            misDate: e.target.value,
                          })
                        }
                        type="datetime-local"
                        id="misDate"
                        name="misDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Fieldset>
            <br />
            <Fieldset legend="Cost Price">
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Cost Price
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-3">
                      <div className="row">
                        <label className="col-sm-12 col-md-6 col-form-label">
                          VAT
                        </label>
                        <div className="col-sm-12 col-md-6">
                          <Checkbox
                            checked={this.state.isVatIncludedCost}
                            onChange={(e) =>
                              this.handleCostTaxFlagChange(e.checked)
                            }
                          ></Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="row">
                        <div className="col-sm-12 col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={this.state.costBaseAmount}
                            onChange={(e) =>
                              this.handleCostTaxChange(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            readOnly={true}
                            value={this.state.costTaxAmount}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            readOnly={true}
                            value={this.state.costTotalAmount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fieldset>
            <br />
            <Fieldset legend="Sell Price">
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Sell Price
                </label>
                <div className="col-sm-12 col-md-10">
                  <div className="row">
                    <div className="col-3">
                      <div className="row">
                        <label className="col-sm-12 col-md-6 col-form-label">
                          VAT
                        </label>
                        <div className="col-sm-12 col-md-6">
                          <Checkbox
                            checked={this.state.isVatIncludedSell}
                            onChange={(e) =>
                              this.handleSellTaxFlagChange(e.checked)
                            }
                          ></Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="row">
                        <div className="col-sm-12 col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            value={this.state.sellBaseAmount}
                            onChange={(e) =>
                              this.handleSellTaxChange(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            readOnly={true}
                            value={this.state.sellTaxAmount}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            readOnly={true}
                            value={this.state.sellTotalAmount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fieldset>
            <br />
            <Fieldset legend="Other">
              <div className="form-group row">
                <label className="col-sm-12 col-md-2 col-form-label">
                  Remarks
                </label>
                <div className="col-sm-12 col-md-10">
                  <textarea
                    className="form-control"
                    value={this.state.remarks}
                    onChange={(e) =>
                      this.handleChange({ remarks: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
            </Fieldset>
            {this.state.roleId && this.state.roleId == 1 && (
              <Fieldset legend="Finance">
                <div className="form-group row">
                  <label className="col-sm-12 col-md-2 col-form-label">
                    Only For Finance
                  </label>
                  <div className="col-sm-12 col-md-10">
                    <Checkbox
                      checked={this.state.isFinance}
                      onChange={(e) =>
                        this.handleChange({ isFinance: e.checked })
                      }
                    ></Checkbox>
                  </div>
                </div>
              </Fieldset>
            )}
            <br />
            <div className="form-group row">
              <div className="col-sm-12 col-md-12"></div>
            </div>
          </form>
        </Dialog>

        <Dialog
          maximizable
          style={{ width: "80vw" }}
          header="Service Voucher"
          visible={this.state.isModalOpenCopy}
          headerStyle={{ backgroundColor: "#fd6511", color: "#FFF" }}
          contentStyle={{ padding: "25px 1.5rem 2rem 1.5rem" }}
          onHide={(e) => {
            this.setState({ isModalOpenCopy: false });
          }}
        >
          {this.state.selectedItem && (
            <MiscellaneousServiceVoucher
              selectedItem={this.state.selectedItem}
            />
          )}
        </Dialog>
        {this.state.isError ? <p>{this.state.errorMessage}</p> : <p></p>}
      </div>
    );
  }
}

export default Miscellaneous;
