import React, { useState } from "react";
import 'webdatarocks/webdatarocks.css'
import { Chart } from "primereact/chart";

class HBarChart extends React.Component {
  
  render() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    debugger
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const invoice=this.props.invoice.reduce((a,v) =>  a = a + parseFloat(v.lineItemAmount) , 0 );
    const receipt=this.props.receipt.reduce((a,v) =>  a = a + parseFloat(v.amount) , 0 );
    const paymentVoucher=this.props.voucher.reduce((a,v) =>  a = a + parseFloat(v.amount) , 0 );

    const data = {
      labels: [
        "",
      ],
      datasets: [
        {
            label: 'Invoice',
            backgroundColor: documentStyle.getPropertyValue("#495057"),
            borderColor: documentStyle.getPropertyValue("#495057"),
            data: [invoice]
        },
        {
            label: 'Receipt',
            backgroundColor: documentStyle.getPropertyValue("#fd6511"),
            borderColor: documentStyle.getPropertyValue("#fd6511"),
            data: [receipt]
        },
        
        {
          label: 'Payment Voucher',
          backgroundColor: documentStyle.getPropertyValue("#6c757d"),
          borderColor: documentStyle.getPropertyValue("#6c757d"),
          data: [paymentVoucher]
      },
    ]
    };
    const options = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
          legend: {
              labels: {
                  fontColor: textColor
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: textColorSecondary,
                  font: {
                      weight: 500
                  }
              },
              grid: {
                  display: false,
                  drawBorder: false
              }
          },
          y: {
              ticks: {
                  color: textColorSecondary
              },
              grid: {
                  color: surfaceBorder,
                  drawBorder: false
              }
          }
      }
  };
    return (
      <>
       <Chart
          type="bar"
          data={data}
          options={options}
        />
      </>
    );
  }
}

export default HBarChart;
