import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import dayjs, { Dayjs } from "dayjs";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

import "../src/styles/style.css";
import HBarChart from "./HBarChart";
class Finances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finance: [],
      receipts: [],
      invoices: [],
      vouchers: [],
    };
  }
  authHeader = () => {
    // return authorization header with jwt token
    const currentUser = localStorage.getItem("token");
    if (currentUser) {
      return { Authorization: `Bearer ${currentUser}` };
    } else {
      return {};
    }
  };
  componentDidMount = async () => {
    await this.getJobFinances();
  };
  footerGroup = () => {
    return (
      <ColumnGroup>
        <Row>
          <Column
            footer="Totals:"
            colSpan={3}
            footerStyle={{ textAlign: "right" }}
          />
          <Column footer={100} />
          <Column footer={200} />
        </Row>
      </ColumnGroup>
    );
  };
  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.removeItem("token");
          localStorage.removeItem("roleId");
          localStorage.removeItem("fullName");
          localStorage.removeItem("instituteId");
          localStorage.removeItem("profileURL");

          window.location.href = `${process.env.PUBLIC_URL}/login`;
        }
      }
      return data;
    });
  }
  getJobFinances = async () => {
    this.props.setLoader(true);
    const receiptList = [];
    const proformaInvoicesReceiptsList = [];
    const invoiceList = [];
    const proformaInvoicesList = [];
    const voucherList = [];
    const requestOptions = { method: "GET", headers: this.authHeader() };
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/Job/GetJobFinancRreceiptAndPaymentsJobCardById?` +
        new URLSearchParams({
          id: this.props.id,
        }),
      requestOptions
    )
      .then(this.handleResponse)
      .then((response) => {
        response.jobCardFinance.jobCardFinanceRreceipt.map((i) => {
          receiptList.push({
            id: i.id,
            receiptCode: i.receiptCode,
            invoiceNo: i.invoiceNo,
            receiptDate:
              i.receiptDate == null
                ? null
                : dayjs(i.receiptDate).format("DD/MM/YYYY"),
            amount: i.amount == null ? null : i.amount.toFixed(2),
            remarks: i.remarks,
          });
        });

        response.jobCardFinance.jobCardFinanceProformaInvoicesReceipt.map(
          (i) => {
            proformaInvoicesReceiptsList.push({
              id: i.id,
              receiptCode: i.receiptCode,
              invoiceNo: i.invoiceNo,
              receiptDate:
                i.receiptDate == null
                  ? null
                  : dayjs(i.receiptDate).format("DD/MM/YYYY"),
              amount: i.amount == null ? null : i.amount.toFixed(2),
              remarks: i.remarks,
            });
          }
        );

        response.jobCardFinance.jobCardFinancePaymentVouchers.map((i) => {
          voucherList.push({
            id: i.id,
            voucherCode: i.voucherCode,
            vendorName: i.vendorName,
            voucherDate:
              i.voucherDate == null
                ? null
                : dayjs(i.voucherDate).format("DD/MM/YYYY"),
            amount: i.amount == null ? null : i.amount.toFixed(2),
            remarks: i.remarks,
          });
        });

        response.jobCardFinance.jobCardFinanceInvoices.map((i) => {
          invoiceList.push({
            id: i.id,
            invoiceCode: i.invoiceCode,
            invoiceDate:
              i.invoiceDate == null
                ? null
                : dayjs(i.invoiceDate).format("DD/MM/YYYY"),
            invoiceDueDate:
              i.invoiceDueDate == null
                ? null
                : dayjs(i.invoiceDueDate).format("DD/MM/YYYY"),
            amount: i.amount == null ? null : i.amount.toFixed(2),
            lineItemAmount: i.lineItemTotalAmount == null ? null : i.lineItemTotalAmount.toFixed(2),
            remarks: i.remarks,
          });
        });
        response.jobCardFinance.jobCardFinanceProformaInvoices.map((i) => {
          proformaInvoicesList.push({
            id: i.id,
            invoiceCode: i.invoiceCode,
            invoiceDate:
              i.invoiceDate == null
                ? null
                : dayjs(i.invoiceDate).format("DD/MM/YYYY"),
            invoiceDueDate:
              i.invoiceDueDate == null
                ? null
                : dayjs(i.invoiceDueDate).format("DD/MM/YYYY"),
            amount: i.amount == null ? null : i.amount.toFixed(2),
            remarks: i.remarks,
          });
        });
        debugger;
        this.props.setLoader(false);
        this.setState({
          receipts: receiptList,
          proformaInvoicesReceipts: proformaInvoicesReceiptsList,
          vouchers: voucherList,
          invoices: invoiceList,
          proformaInvoices: proformaInvoicesList,
        });
      });
  };
  render() {
    var totalIncome = 0;
    var totalExpences = 0;
    var profitAndLost = 0;

    if (this.state.vouchers && this.state.vouchers.length > 0) {
      totalExpences = this.state.vouchers.reduce((a, v) => {
        var p = parseFloat(a) + parseFloat(v.amount);
        return p.toFixed(2);
      }, 0);
    }

    if (this.state.receipts && this.state.receipts.length > 0) {
      totalIncome = this.state.receipts.reduce((a, v) => {
        var p = parseFloat(a) + parseFloat(v.amount);
        return p.toFixed(2);
      }, 0);
    }
    profitAndLost = (totalIncome - totalExpences).toFixed(2);
    return (
      <div>
        <div className="pb-20">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <TabView>
                    <TabPanel header="Payment Vouchers ">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={this.state.vouchers}
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="voucherDate"
                          sortable
                          header="Date"
                        ></Column>
                        <Column
                          field="voucherCode"
                          sortable
                          header="Voucher Number"
                        ></Column>
                        <Column
                          field="vendorName"
                          sortable
                          header="Vendor Name"
                        ></Column>
                        <Column
                          field="amount"
                          sortable
                          header="Amount Paid"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                      </DataTable>
                    </TabPanel>
                    <TabPanel header="Proforma Invoices">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={this.state.proformaInvoices}
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="invoiceDate"
                          sortable
                          header="Date"
                        ></Column>
                        <Column
                          field="invoiceCode"
                          sortable
                          header="Invoice Number"
                        ></Column>
                        <Column
                          field="invoiceDueDate"
                          sortable
                          header="Due Date"
                        ></Column>
                        <Column
                          field="amount"
                          sortable
                          header="Amount"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                      </DataTable>
                    </TabPanel>
                    <TabPanel header="Proforma Invoice Receipts">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={this.state.proformaInvoicesReceipts}
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="receiptDate"
                          sortable
                          header="Date"
                        ></Column>
                        <Column
                          field="receiptCode"
                          sortable
                          header="Receipt Number"
                        ></Column>
                        <Column
                          field="invoiceNo"
                          sortable
                          header="Invoice Number"
                        ></Column>
                        <Column
                          field="amount"
                          sortable
                          header="Amount"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                      </DataTable>
                    </TabPanel>
                    <TabPanel header="Tax Invoices">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={this.state.invoices}
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="invoiceDate"
                          sortable
                          header="Date"
                        ></Column>
                        <Column
                          field="invoiceCode"
                          sortable
                          header="Invoice Number"
                        ></Column>
                        <Column
                          field="invoiceDueDate"
                          sortable
                          header="Due Date"
                        ></Column>
                        <Column
                          field="amount"
                          sortable
                          header="Invoice Amount"
                        ></Column>
                        <Column
                          field="lineItemAmount"
                          sortable
                          header="Job Card Amount"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                      </DataTable>
                    </TabPanel>
                    <TabPanel header="Tax Invoice Receipts">
                      <DataTable
                        stripedRows
                        showGridlines
                        value={this.state.receipts}
                        selectionMode="single"
                        dataKey="id"
                        metaKeySelection={false}
                        sortMode="multiple"
                        paginator
                        rows={20}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: "50rem" }}
                      >
                        <Column
                          field="receiptDate"
                          sortable
                          header="Date"
                        ></Column>
                        <Column
                          field="receiptCode"
                          sortable
                          header="Receipt Number"
                        ></Column>
                        <Column
                          field="invoiceNo"
                          sortable
                          header="Invoice Number"
                        ></Column>
                        <Column
                          field="amount"
                          sortable
                          header="Amount"
                        ></Column>
                        <Column
                          field="remarks"
                          sortable
                          header="Remarks"
                        ></Column>
                      </DataTable>
                    </TabPanel>
                  </TabView>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      {this.state.vouchers &&
                        this.state.receipts &&
                        this.state.invoices && (
                          <HBarChart
                            voucher={this.state.vouchers}
                            receipt={this.state.receipts}
                            invoice={this.state.invoices}
                          />
                        )}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    {this.state.vouchers &&
                      this.state.receipts &&
                      this.state.invoices && (
                        <>
                          <div className="col-3" style={{textAlign:"center"}}>
                            <h5>Total Income</h5>
                            {totalIncome}
                          </div>
                          <div className="col-3" style={{textAlign:"center"}}>
                            <h5>Total Expences</h5>
                            {totalExpences}
                          </div>
                          <div className="col-3" style={{textAlign:"center"}}>
                            <h5>Profit/Loss</h5>
                            {profitAndLost}
                          </div>
                          <div className="col-3" style={{textAlign:"center"}}>
                            {profitAndLost !=0 && (
                            <>
                            {profitAndLost > 0 ? (
                              <img
                                src="../vendors/images/increase.png"
                                width="50"
                                alt=""
                              />
                            ) : (
                              <img
                                src="../vendors/images/decrease.png"
                                width="50"
                                alt=""
                              />
                            )}
                            </>)}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Finances;
