import React from "react";
import { ToastContainer, toast } from "react-toastify";
import BatchInner from "./BatchInner";

class HotelServiceVoucherInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  HotelCol = () => {
    
    return (
      <th
        id="hotelTh"
        style={{
          fontFamily:
            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
          fontSize: "18px",
          whiteSpace: "nowrap",
          textAlign: "left",
          verticalAlign: "middle",
          paddingLeft: "0px",
          border: "0",
        }}
      >
        <strong style={{ fontSize: "20px" }}>Emergency No:</strong>
        <br />
        +971 56 411 9359 / +917 30 620 5454
      </th>
    );
  };
  render() {
    return (
      <div id="report">
        <table width="100%" height="265" border="0" align="left">
          <tbody>
            <tr colspan="2" style={{ verticalAlign: "top" }}>
              <td>
                <p style={{ textAlign: "left" }}>
                  <img
                    src="../vendors/images/holiday_makers_rlogo.jpg"
                    width="100%"
                    alt=""
                  />
                </p>
              </td>
              <td colspan="3">
                <p style={{ textAlign: "center" }}>
                  <font
                    color="#FF6C04"
                    style={{
                      fontSize: "58px",
                      fontFamily:
                        "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                    }}
                  >
                    Hotel Voucher
                  </font>
                </p>
              </td>
              <td>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily:
                      "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                  }}
                >
                  <b>Holiday Makers Tourism LLC</b>
                  <br />
                  City 4 Building, Al Garhoud
                  <br />
                  Dubai, United Arab Emirates
                  <br />
                  Pin: 0000
                  <br />
                  Tel: +971 4 257 9192
                  <br />
                  Web: www.holidaymakers.ae
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <table class="table">
                  <thead>
                    <tr
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        background: "#f6f6f7!important",
                      }}
                    >
                      <th
                        width="20%"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "20px",
                          whiteSpace: "nowrap",
                          padding: "3px 3px 3px 10px",
                          textAlign: "left",
                          width: "70%",
                          verticalAlign: "middle",
                          border: "0",
                        }}
                      >
                        <strong style={{ paddingLeft: "10px" }}>
                          Booking Details:
                        </strong>
                      </th>
                      {this.HotelCol()}
                    </tr>
                  </thead>
                </table>
              </td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <td colspan="6">
                <table width="100%" border="0" align="left">
                  <tbody>
                    <tr style={{ verticalAlign: "top" }}>
                      <td width={"70%"}>
                        <table width="100%" border="0" align="left">
                          <tbody>
                            <tr style={{ verticalAlign: "top" }}>
                              <td>
                                <p
                                  style={{
                                    paddingLeft: "10px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <strong className="weight-600">
                                    HOTEL NAME
                                  </strong>
                                  <br />
                                  <strong className="weight-600">
                                    {this.props.selectedItem.hotelName}
                                    <br />
                                  </strong>
                                  {this.props.selectedItem.hotelAddress1 && (
                                    <>
                                      {this.props.selectedItem.hotelAddress1}
                                      <br />
                                    </>
                                  )}
                                  {this.props.selectedItem.hotelAddress2 && (
                                    <>{this.props.selectedItem.hotelAddress2}</>
                                  )}
                                </p>
                              </td>
                              <td>
                                <p>
                                  HOTEL CONFIRMATION
                                  <br />
                                  <strong className="weight-600">
                                    {this.props.selectedItem.hotelConfirmation}
                                    <br />
                                  </strong>
                                </p>
                              </td>
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                              <td colspan="2">
                                <hr />
                              </td>
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                              <td>
                                <p
                                  style={{
                                    paddingLeft: "10px",
                                    fontSize: "15px",
                                  }}
                                >
                                  CITY / COUNTRY
                                  <br />
                                  Dubai, United Arab Emirates
                                </p>
                              </td>
                              <td>
                                <p>
                                  HM BOOKING ID
                                  <br />
                                  {this.props.selectedItem.hotelCode}
                                </p>
                              </td>
                              {/* <td
                                style={{
                                  backgroundColor: "#dee2e6",
                                  verticalAlign: "middle",
                                  height: "150px",
                                }}
                              ></td> */}
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                              <td colspan="2">
                                <hr />
                              </td>
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                              <td>
                                <p
                                  style={{
                                    paddingLeft: "10px",
                                    fontSize: "15px",
                                  }}
                                >
                                  GUEST NAME
                                  <br />
                                  {this.props.selectedItem.paxName}
                                </p>
                              </td>
                              <td>
                                <p>
                                  NIGHT(S)
                                  <br />
                                  {this.props.selectedItem.nights}
                                </p>
                              </td>
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                              <td colspan="2">
                                <hr />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td rowSpan={3}>
                        <table width="100%" border="0" align="left">
                          <tbody>
                            <tr
                              style={{
                                verticalAlign: "middle",
                                backgroundColor: "#dee2e6",
                              }}
                            >
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  height: "150px",
                                }}
                              >
                                <p
                                  style={{
                                    paddingLeft: "20px",
                                    fontSize: "20px",
                                    margin: "0",
                                  }}
                                >
                                  CHECK-IN
                                  <br />
                                  <strong
                                    className="weight-600"
                                    style={{ fontSize: "24px" }}
                                  >
                                    {this.props.selectedItem.checkInDate}
                                    <br />
                                  </strong>
                                </p>
                              </td>
                            </tr>
                            <tr
                              style={{
                                verticalAlign: "middle",
                                backgroundColor: "#dee2e6",
                              }}
                            >
                              <td>
                                <hr
                                  style={{
                                    margin: "0",
                                    height: "2px",
                                    backgroundColor: "#fff",
                                  }}
                                />
                              </td>
                            </tr>
                            <tr
                              style={{
                                verticalAlign: "middle",
                                backgroundColor: "#dee2e6",
                              }}
                            >
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  height: "150px",
                                }}
                              >
                                <p
                                  style={{
                                    paddingLeft: "20px",
                                    fontSize: "20px",
                                    margin: "0",
                                  }}
                                >
                                  CHECK-OUT
                                  <br />
                                  <strong
                                    className="weight-600"
                                    style={{ fontSize: "24px" }}
                                  >
                                    {this.props.selectedItem.checkOutDate}
                                    <br />
                                  </strong>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="6">
                <table class="table table-striped">
                  <thead>
                    <tr
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        background: "#f6f6f7!important",
                      }}
                    >
                      <th
                        width="43%"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Room Type</strong>
                      </th>
                      <th
                        width="19%"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Adults</strong>
                      </th>
                      <th
                        width="19%"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Children</strong>
                      </th>
                      <th
                        width="19%"
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        <strong>Infants</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className="tableOddrow"
                      style={{
                        fontFamily:
                          "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                        fontSize: "14.5px",
                        background: "#f5cc98 !important",
                      }}
                    >
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        {this.props.selectedItem.roomType}
                      </td>
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        <strong>{this.props.selectedItem.adults}</strong>
                      </td>
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        <strong>{this.props.selectedItem.children}</strong>
                      </td>
                      <td
                        style={{
                          fontFamily:
                            "Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif",
                          fontSize: "14.5px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        {this.props.selectedItem.infants}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <strong>Remarks:</strong>
                <br />
                {this.props.selectedItem.remarks}
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <hr style={{ height: "2px", backgroundColor: "#FF6C04" }} />
              </td>
            </tr>

            <tr>
              <td colspan="6">
                <p style={{ lineHeight: "30px" }}>
                  {" "}
                  <strong>Check-in/Check-out Timings & Policies</strong>
                  <br />
                  • The usual check-in time is 2:00 PM hours however this might
                  vary from hotel to hotel and with different destinations.
                  <br />
                  • Rooms may not be available for early check-in, unless
                  especially required in advance. However, luggage may be
                  deposited at the hotel reception and collected once the room
                  is allotted.
                  <br />
                  • Note that reservation may be canceled automatically after
                  18:00 hours if hotel is not informed about the approximate
                  time of late arrivals.
                  <br />
                  • The usual checkout time is at 12:00 hours however this might
                  vary from hotel to hotel and with different destinations. Any
                  late checkout may involve additional charges. Please check
                  with the hotel reception in advance.
                  <br />
                  • For any specific queries related to a particular hotel,
                  kindly reach out to local support team for further assistance.
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <p style={{ lineHeight: "30px" }}>
                  {" "}
                  <strong>Nationality & Domicile</strong>
                  <br />
                  Passengers travelling to destination where guest is holding a
                  local residency, booking should be searched with Country of
                  Residence as Nationality in order to avail the valid rates.
                  (i.e. Indian National holding UAE Residence Permit should
                  select Emirati as nationality for search). In case of wrong
                  residency or nationality selected by user at the time of
                  booking; the supplement charges may be applicable and need to
                  be paid directly to the hotel by guest on check in/check out.
                  Additional supplement charges may be charged by the Hotel
                  (which the Guest have to pay directly at the hotel) If the
                  lead guest Nationality is different than the Nationality of
                  the other accompanied guests. For more details you can reach
                  out to our operation Team for clarification.
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <p style={{ lineHeight: "30px" }}>
                  {" "}
                  <strong>Booking Notes</strong>
                  <br />
                  Bookings are payable as per reservation details.Please collect
                  all extras directly from clients prior to departure.All
                  vouchers issued are on the condition that all arrangements
                  operated by person or bodies are made as agents only and that
                  they shall not be responsible for any damage, loss, injury,
                  delay or inconvenience caused to passengers as a result of any
                  such arrangements. We will not accept any responsibility for
                  additional expenses due to the changes or delays in air, road,
                  rail, sea or indeed any other causes, all such expenses will
                  have to be borne by passengers.
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <p style={{ lineHeight: "30px" }}>
                  {" "}
                  <strong>Important Notes & Conditions</strong>
                  <br />
                  <strong>Note : </strong>Check your Reservation details
                  carefully and inform us immediately.if you need any further
                  clarification, please do not hesitate to contact us.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default HotelServiceVoucherInner;
